import React from 'react';
import Hero404 from 'src/components/Hero/Hero404';
import { useStaticQuery, graphql } from 'gatsby';

const NotFoundPage = ({ site, options }) => {
    const { fileName } = useStaticQuery(
        graphql`
            query MyQuery {
              fileName: file(relativePath: {eq: "404.jpg"}) {
                id
                absolutePath
                childImageSharp{
                  fluid(maxWidth: 750, quality: 60){
                    aspectRatio
                    base64
                    originalImg
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                  }
                }
              }
            }
        `
    );
    const image = fileName.childImageSharp.fluid;
    return (
                <>
                    <Hero404 image={image} />
                </>
           );
};
export default NotFoundPage;